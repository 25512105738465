import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Container from "../components/container";
import SEO from '../components/seo';
import GetStarted from '../components/blog-bottom-cta';
import BlogImg2 from "../images/blog-cta-target.png";
import SubscribeBox1 from '../components/SubscribeBox1';
import kebabCase from "lodash/kebabCase"
import parse from "html-react-parser"
import popularArr from '../constants/popularPosts'
import authors from '../utils/authors'
import Metatags from "../components/Metatags";

const NavLink = props => {
    if (props.test !== null) {
        return <Link className={props.className} to={`${(props.url).replace(/\/$/, "")}`}>{props.text}</Link>
    } else {
        return <span className={props.className}>{props.text}</span>
    }
}

const Blog = ({ data, pageContext }) => {
    const { pageNumber, pageCount, currentPagePath, nextPagePath, previousPagePath } = pageContext;
    const [activeClass, setactiveClass] = useState('normal');
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let active = '';
            if (window.scrollY > 2000) {
                active = 'top-blog';
            }
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 800)) {
                active = 'normal';
            }
            if (window.scrollY === 0) {
                active = 'normal';
            }
            setactiveClass(active)
        });
    });
    const posts = data.posts.nodes

    if (!posts.length) {
        return (
            <Layout className="blog-page">
                <Metatags
            title="Optinly Blog | A Powerful Opt-in Tool for Conversion!"
            description="Blogs to help convert website visitors into potential leads and tips to grow your email list by Optinly."
            keywords={[`woocommerce abandoned cart recovery`, `shopify customer retention`, `woocommerce sales boost techniques`, `Customer retention strategies`, `customer retaining techniques`, `boost eCommerce sales`, `retention marketing`, `retention marketing`, `acquisition marketing`]}
            url = {`https://www.optinly.com/blog/`}
            pathname = "/blog/"
        />
                {/* <SEO title="Optinly Blog | A Powerful Opt-in Tool for Conversion!"
                    description="Blogs to help convert website visitors into potential leads and tips to grow your email list by Optinly."
                    keywords={[`woocommerce abandoned cart recovery`, `shopify customer retention`, `woocommerce sales boost techniques`, `Customer retention strategies`, `customer retaining techniques`, `boost eCommerce sales`, `retention marketing`, `retention marketing`, `acquisition marketing`]} /> */}
                <Container>
                    {/* <Bio /> */}
                    <p>
                        No blog posts found. Add posts to your WordPress site and they'll
                        appear here!
                    </p>
                </Container>
                <GetStarted />
            </Layout>
        )
    }

    return (
        <Layout className="blog-page">

            <Metatags
            title={`Blog - Learn to boost engagement and audience building with us`}
            description = "Unlock organic audience building secrets with our expert tips and trends on lead capturing, lead nurturing and more. Stay updated on Optinly's product announcements."
            keywords= {[`woocommerce abandoned cart recovery`, `shopify customer retention`, `woocommerce sales boost techniques`, `Customer retention strategies`, `customer retaining techniques`, `boost eCommerce sales`, `retention marketing`, `retention marketing`, `acquisition marketing`]}
            url = {`https://www.optinly.com/blog/${pageNumber}`}
            pathname = {`/${pageNumber}`}
        />

            {/* <SEO title={`Optinly Blog - ${pageNumber} of ${pageCount} A Powerful Opt-in Tool for Conversion!`}
                description="Blogs to help convert website visitors into potential leads and tips to grow your email list by Optinly."
                keywords={[`woocommerce abandoned cart recovery`, `shopify customer retention`, `woocommerce sales boost techniques`, `Customer retention strategies`, `customer retaining techniques`, `boost eCommerce sales`, `retention marketing`, `retention marketing`, `acquisition marketing`]} /> */}

            <div className="py-5 jumbotron blog-topbar-banner">
                <div className="container text-center">
                    <h1 className="blog-page-title">Optinly Blog</h1>
                    <p>Growth hacks, stories, case studies to help convert website visitors into potential leads and to customers - drive conversions and sales on the go!</p>
                </div>
            </div>
            <Container>
                <div className="row">
                    <div className="col-md-8">
                        <div className="blog-list-container">
                            <div className="row">
                                {posts.map(post => {
                                    const featuredImage = {
                                        fluid: post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
                                        alt: post.featuredImage?.node?.alt || ``,
                                        }
                                    const authorImg = post.author?.node?.avatar?.url;
                                    const authorName = post.author?.node?.name;

                                    const authorBio = authors.find(x => x.name === post.author?.node?.name) || 'Guest User' ;

                                    return(
                                    <div className="col-sm-12" key={post.uri}>
                                        <div className="blog-post">
                                            <div className="image-section">
                                                {featuredImage?.fluid && (
                                                    <Link to={`/blog/${post.slug}/`}>
                                                        <GatsbyImage image={featuredImage.fluid} 
                                                            alt={featuredImage.alt} />
                                                    </Link>
                                                )}
                                                {
                                                    // post.category &&
                                                    // <Link className="category-name" to={`/category/${kebabCase(post.category.categorySlug)}/`}> {post.category.categoryName}</Link>
                                                }
                                            </div>
                                            <div className="content-section">
                                                <h2 className="post-title">
                                                    <Link to={`/blog/${post.slug}/`}>{parse(post.title)}</Link>
                                                </h2>
                                                <div className="meta-info">
                                                    <span className="author-name">
                                                        {(authorBio.imageUrl == null || authorBio.imageUrl == 'undefined') ?
                                                            <><img src={authorImg} alt={authorName} />&nbsp;</>
                                                            :
                                                            <><img src={authorBio.imageUrl} alt={authorName} />&nbsp;</>
                                                        }
                                                        {authorName == "Guest User" ? 'Guest User' : ` ${authorName}`}
                                                    </span> &nbsp; | &nbsp;
                                                    <span className="post-date">
                                                        {post.date}
                                                    </span>  
                                                    &nbsp; | &nbsp;
                                                    <span className="post-category">
                                                        {
                                                            post.categories.nodes.length > 0 &&
                                                            post.categories.nodes.map((cat, index) => {
                                                                return (
                                                                    <>
                                                                    <Link className="category-name" to={`/category/${kebabCase(cat.slug)}/`}> {cat.name}</Link>
                                                                    { (post.categories.nodes.length > 1 && (index < post.categories.nodes.length-1)) ? ',' : ''}
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </span>
                                                    {/*&nbsp; | &nbsp;
                                                        <span className="reading-time">
                                                        {post.node.fields.readingTime.text}
                                                    </span> */}
                                                </div>
                                                <section itemProp="description">{parse(post.excerpt)}</section>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                )}
                            </div>
                            <div className="pagination-links">
                                <NavLink className="previousLink" test={previousPagePath} url={`${previousPagePath}`} text={<FaAngleDoubleLeft />} />
                                {Array.from({ length: pageCount }, (_, i) => (
                                    <Link key={`pagination-number${i + 1}`} className={`pagination-number ${(currentPagePath == `/blog${i === 0 ? "" : "/" + (i + 1) }` ? 'active' : '' )} `}
                                        to={`/blog${i === 0 ? "" : "/" + (i + 1) + "/"}`}>
                                        {i + 1}
                                    </Link>
                                ))}
                                <NavLink className="nextLink" test={nextPagePath} url={`${nextPagePath}`} text={<FaAngleDoubleRight />} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="blog-sidebar">
                            {/* <div className="widget-box">
                                <h4 className="widget-title">Featured tags</h4>
                                <div className="tags-list">
                                    {
                                        data.tagsGroup.nodes.map((tag, index) => {
                                            if(tag.count > 0){
                                                return (
                                                    <Link className="tag-item" to={`/tags/${tag.slug}`} key={tag.slug}>{tag.name}</Link>
                                                )
                                            }
                                            return;
                                        })
                                    }
                                </div>
                            </div> */}
                            <SubscribeBox1 />
                            <div className="widget-box">
                                <h4 className="widget-title">Categories</h4>
                                <div className="category-list">
                                    {
                                        data.categoryGroup.nodes.map((category, index) => {
                                            if(category.count > 0){
                                                return (
                                                    <Link className="category-item" to={category.uri} key={category.slug}>{category.name}</Link>
                                                )
                                            }
                                            return;
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`widget-box ${activeClass}`}>
                                {/* <h4 className="widget-title">CTA Box</h4> */}
                                <div className="cta-box">
                                    <img src={BlogImg2} className="widget-image" alt="Optinly - CTA" />
                                    <h4>Engage visitors, capture leads &amp; monetize website traffic!</h4>
                                    <a href="https://app.optinly.com/" target="_blank" className="mt-3 btn btn-primary">Try Optinly Free!</a>
                                    <p className="mt-3 mb-3 text-muted"><small>*No Credit Card Required <br /> <strong>*Free Forever</strong></small></p>
                                </div>
                            </div>
                            <div className="widget-box popular-blog-widget">
                                <h4 className="widget-title">Popular posts</h4>
                                {popularArr.map((post, index) => {
                                    return (
                                        <div className="popular-blog-post" key={post.title}>
                                            <div className="image-section">
                                                <Link to={post.slug}>
                                                    <img src={post.imgSrc}
                                                        alt={post.title} />
                                                </Link>
                                            </div>
                                            <div className="content-section">
                                                <h4>
                                                    <Link to={`${post.slug}/`}>{post.title}</Link>
                                                </h4>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <GetStarted />
        </Layout>
    )
}

export default Blog

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        id
        slug
        date(formatString: "MMMM DD, YYYY")
        modified
        title
        excerpt
        featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
        }
        author {
            node {
              avatar {
                url
              }
              name
            }
        }
        categories {
            nodes {
              name
              slug
              count
            }
        }
      }
    }
    tagsGroup: allWpTag(limit: 20) {
        nodes {
            name
            slug
            uri
            count
        }
        totalCount
    }
    categoryGroup: allWpCategory(limit: 20) {
        nodes {
            name
            slug
            uri
            count
        }
        totalCount
    }
  }
`